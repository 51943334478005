import React, { useEffect, useState } from 'react'
import styles from './Home.module.css'
import img_1 from '../../img/main_temp1.png'
import img_2 from '../../img/main_temp2.png'
import img_3 from '../../img/main_temp3.png'

const Home = () => {
  const [target, setTarget] = useState(1)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTarget((prevTarget) => {
        if (prevTarget === 1) {
          return 2;
        } else if (prevTarget === 2) {
          return 3;
        } else if (prevTarget === 3) {
          return 1;
        }
      });
    }, 2000);

    return () => clearInterval(intervalId);
  }, [])

  const exportTargetImg = () => {
    switch (target) {
      case 1:
        return `url(${img_3})`
      case 2:
        return `url(${img_1})`
      case 3:
        return `url(${img_2})`
    }
  }

  return (
    <div>

      <div className={`${styles.main_img_wrap}`} style={{ backgroundImage: exportTargetImg() }}>
        <div className={`${styles.main_img_text} ${target === 1 && styles.target1}`}>
          <h1 style={{ marginBottom: '10px' }}>국내최초 음성인식 모션커튼1</h1>
          <p>"열어, 닫아"</p>
        </div>
        <div className={`${styles.main_img_text} ${target === 2 && styles.target2}`}>
          <h1 style={{ marginBottom: '10px' }}>국내최초 음성인식 모션커튼2</h1>
          <p>"열어, 닫아"</p>
        </div>
        <div className={`${styles.main_img_text} ${target === 3 && styles.target3}`}>
          <h1 style={{ marginBottom: '10px' }}>국내최초 음성인식 모션커튼3</h1>
          <p>"열어, 닫아"</p>
        </div>
      </div>



      {/* <img src="http://cherish2.godohosting.com/21new/new_main_doctor.jpg" style={{ width: '100%' }} /> */}
    </div>
  )
}

export default Home