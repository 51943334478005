import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './AppLayout.module.css'

const AppLayout = () => {
    return (
        <div>
            <div className={styles.wrap}>
                <div className={styles.main_wrap}>
                    <div className={styles.wrap1} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='center'>
                            <img src={`${process.env.PUBLIC_URL}/logo.png`} style={{ width: '70px' }} />
                            <h1 className={`${styles.title} ${styles.trans_text}`}>illamotion</h1>
                        </div>
                        <div className={styles.btn_wrap}>
                            <div>
                                <p className={`${styles.menu_p} ${styles.trans_text} ${styles.menu1}`}>company</p>
                                <ul className={`${styles.menu_ul} ${styles.menu_ul1}`}>
                                    <li>일라모션</li>
                                    <li>연혁(실적)</li>
                                    <li>오시는 길</li>
                                </ul>
                            </div>
                            <div>
                                <p className={`${styles.menu_p} ${styles.trans_text} ${styles.menu2}`}>products</p>
                                <ul className={`${styles.menu_ul} ${styles.menu_ul2}`}>
                                    <li>제품군1</li>
                                    <li>제품군2</li>
                                    <li>제품군3</li>
                                    <li>제품군4</li>
                                    <li>제품군5</li>
                                    <li>제품군6</li>
                                </ul>
                            </div>
                            <div>
                                <p className={`${styles.menu_p} ${styles.trans_text} ${styles.menu3}`}>support</p>
                                <ul className={`${styles.menu_ul} ${styles.menu_ul3}`}>
                                    <li>FAQ</li>
                                    <li>Q&A</li>
                                    <li>Manual</li>
                                    <li>Category</li>
                                </ul>
                            </div>

                        </div>
                    </div>


                    <div className={styles.wrap2} style={{ display: 'flex' }}>
                        <p className={`${styles.menu_p} ${styles.trans_text}`}>login</p>
                        <p className={`${styles.menu_p} ${styles.trans_text} only_pc`}>signup</p>
                    </div>
                </div>
            </div>

            <Outlet />
        </div>
    )
}

export default AppLayout